import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import VtuComponent from "../components/vtu/vtu.component"

const DustDispersionControlSolutionForCoalTransloadingTerminal = () => {
  return (
    <Layout>
      <VtuComponent />
    </Layout>
  )
}

export default DustDispersionControlSolutionForCoalTransloadingTerminal

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
