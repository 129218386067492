import React, { useEffect, useRef } from "react"
import {useTranslation} from "react-i18next"

import './vtu.component.less';

import wtuMain from "../../assets/img/wtu/wtu_main.jpg";
import wtuSmall from "../../assets/img/wtu/wtu_small.jpg";
import wtu1 from "../../assets/img/wtu/wtu_1.jpg";
import wtu2 from "../../assets/img/wtu/wtu_2.png";
import wtu2M from "../../assets/img/wtu/wtu_2-mobile.jpg";
import VideoVtu from "../../assets/video/vtu.mp4";
import vtu_poster from "../../assets/img/wtu/vtu_poster.jpg"

import Seo from "../seo";
import {useI18next} from "gatsby-plugin-react-i18next";

const VtuComponent = () => {
    const { t, i18n } = useTranslation();
    const { originalPath } = useI18next();
    const videoWtuRef = useRef(undefined);

    useEffect(() => {
        videoWtuRef.current.defaultMuted = true;
    })

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <Seo description={t("metaDescriptionVtu")} lang={i18n.language} title={t("titleVtu")} link={"https://cityair.io/"+ i18n.language + originalPath} />
            <div className="wtu">
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom main-block">
                    <div className="main-block__text">
                        <div className="main-block__title">
                            <span><span
                                dangerouslySetInnerHTML={{__html: t("dustDispersionControlSolution")}}/></span>
                            <span className="main-block__title-purple"> <span
                                dangerouslySetInnerHTML={{__html: t("forCoalTransloadingTerminal")}}/></span>
                        </div>
                        <div className="main-block__description"><span
                            dangerouslySetInnerHTML={{__html: t("oneOfTheLargestStevedore")}}/>
                        </div>
                        <div
                            className="main-block__description main-block__description--others">
                            <span
                                dangerouslySetInnerHTML={{__html: t("theSystemAllowsTo")}}/>
                        </div>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={wtuMain}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                     className="main-block__small-img"
                                     src={wtuSmall}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block simple-content">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span
                                dangerouslySetInnerHTML={{__html: t("objective")}}/>
                        </div>
                        <div className="simple-content__col-right">

                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("despiteTheRelevantPreventive")}}/>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--end-block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("toAvoidEmergenciesThe")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border simple-content">
                    <div className="simple-content__title">
                        <div className="simple-content__col-left">
                            <span
                                dangerouslySetInnerHTML={{__html: t("solution")}}/>
                        </div>
                        <div className="simple-content__col-right">
                        </div>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("continuousAirQualityMonitoring")}}/>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("allTheDataIsAccumulated")}}/>
                            </div>
                            <div
                                className="simple-content__description--block simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("airQualityIndexIsAlso")}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border display-none-mobile simple-content wtu-img">
                    <div className="simple-content__img">
                        <div
                            className="simple-content__col-left simple-content__col-left--img">
                            <img alt="" src={wtu1}/>
                        </div>
                        <div
                            className="simple-content__col-right simple-content__col-right--img">
                            <img alt="" src={wtu2}/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block borders-block simple-content  simple-content__wtu">
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">1
                                </div>
                                <div
                                    className="borders-block__col--title">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("integrationWithExternalEquipment")}}/>
                                </div>
                                <div className="borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("aGoodExampleHowCityAirSolutions")}}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="simple-content--img-mobile display-block-mobile">
                            <img alt="" src={wtu1}/>
                        </div>
                        <div className="borders-block__hr"/>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--number">2
                                </div>
                                <div className="borders-block__col--title"><span
                                    dangerouslySetInnerHTML={{__html: t("remoteMonitoringSolutions")}}/>
                                </div>
                                <div className="borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("cneOfTheMonitorsWasDeployed")}}/>
                                </div>
                            </div>
                        </div>
                        <div
                            className="simple-content--img-mobile simple-content--img-mobile-end display-block-mobile">
                            <img alt="" src={wtu2M}/>
                        </div>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border borders-block simple-content">
                    <div className="borders-block__col--block">
                        <div className="borders-block__col--number">3
                        </div>
                        <div
                            className="borders-block__col--title">
                            <span
                                dangerouslySetInnerHTML={{__html: t("cityScreenPlumeForecastAndDust")}}/>
                        </div>
                    </div>
                    <div className="borders-block__col">
                        <div className="borders-block__col-left">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("cityScreenPlumeIsBasedOnAMathematical")}}/>
                                </div>
                            </div>
                        </div>
                        <div className="borders-block__col-right">
                            <div className="borders-block__col--block">
                                <div className="borders-block__col--point">
                                    <span
                                        dangerouslySetInnerHTML={{__html: t("theToolMayProvideEvaluation")}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrapper_block wrapper_block__block borders-block wtu__img--wrapper">
                    <div>
                        <video loop autoPlay muted playsInline
                                   className="wtu__img"
                                   ref={videoWtuRef}
                                   poster={vtu_poster}>
                            <source src={VideoVtu} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-border simple-content">
                    <div className="simple-content__title">
                        <span dangerouslySetInnerHTML={{__html: t("results")}}/>
                    </div>
                    <div className="simple-content__description">
                        <div className="simple-content__col-left">
                            <div
                                className="simple-content__description--block simple-content__description--big-size">
                                <strong><span
                                    dangerouslySetInnerHTML={{__html: t("realPictureAlreadyOnTheEarlyStage")}}/></strong>
                                <span
                                    dangerouslySetInnerHTML={{__html: t("among11MonitorsOnlyOneHas")}}/>
                            </div>
                        </div>
                        <div className="simple-content__col-right">
                            <div
                                className="simple-content__description--subtitle">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("control")}}/>
                            </div>
                            <div
                                className="simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("preventiveMeasuresBasedOnForecasting")}}/>
                            </div>
                            <div
                                className="simple-content__description--subtitle">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("openness")}}/>
                            </div>
                            <div
                                className="simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("providingPublicAccessToThe")}}/>
                            </div>
                            <div
                                className="simple-content__description--subtitle">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("loweringOfFinancialAndReputationalRisks")}}/>
                            </div>
                            <div
                                className="simple-content__description--small-text">
                                <span
                                    dangerouslySetInnerHTML={{__html: t("theStevedoresAreaIsRegularlyBeing")}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

VtuComponent.propTypes = {}

VtuComponent.defaultProps = {}

export default VtuComponent
